/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.colorPkrClose {
	display: none;
}
.colorPkrShow {
	display: block;
}
.timezone-color {
	color: #28C76F;

}
.project-view-color {
	color: #407EC9;
}
.font-size-25 {
	font-size: 1.25rem ! important;
}
.close {
	top: 2px;
    right: 10px;
}
.upload-button {
	background-color: #702F8A ! important;
}
.navbar-mb {
	margin-bottom: 10px;
}
.navtoggle-mt {
	// margin-top: 9px;
}

// InValid Select
.invalid {
	.select__control {
	  border-color: #ea5455;;
	}
  }

.show-error-msg {
	color: #ea5455;
	display: block;
}
.hide-error-msg {
	display: none;
}
.show-message {
	color: #ea5455;
	display: block;
}
.hide-message {
	display: none;
}
.show-list {
	display: block;
}
.hide-list {
	display: none;
}
.show-add-item {
	display: block;
}
.hide-add-item {
	display: none;
}
.justify-content-center {
	justify-content: center !important;
}

// .react-select {z-index: 9;}
.dataTable-filter-select {z-index: 9;}

.invalid_members, 
.invalid_presenters, 
.invalid_tele, 
.invalid_pre_stage, 
.invalid_post_stage, 
.invalid_stage {
	.select__control {
	  border-color: #ea5455;;
	}
  }
.dataTable-filter-select {
	width: 150px;
}
.setStatus {
	width: 175px;
}
.load-more-btn {
  position: absolute;
  top:87%;
  left:88%;
}
.rdt_TableBody:hover{ cursor: all-scroll; }
.react-dataTable, .rdt_TableBody{ -webkit-overflow-scrolling: touch; }
.react-dataTable::-webkit-scrollbar{ -webkit-appearance: none; height: 10px; }
.rdt_TableBody::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
	height: 10px;
}
.rdt_TableBody::-webkit-scrollbar-track, .react-dataTable::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.rdt_TableBody::-webkit-scrollbar-thumb, .react-dataTable::-webkit-scrollbar-thumb {
	background: #888;
}
.rdt_TableBody::-webkit-scrollbar-thumb:hover, .react-dataTable::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.customCheck .custom-control-label::before, .customCheck .custom-control-label::after{ width: 36px; height: 36px; }

// .auth-bg {background-color: #fff;}
.cardtext-label {color: #5e5873; font-size: 0.857rem;}
.custom-control-Primary {color: #7468f0;}
.table-match {
	.hr-bottom { border-top: 1px solid #EBE9F1;}
	form .col-12:nth-last-child(2) hr{
		display: none;
	}
}

.leadonboard-text-black {
	color: #000;
}

/* src/styles.css */
/**,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --font-color: 230 35% 7%;
}
body {
  color: hsl(var(--font-color));
}*/
img {
  max-width: 100%;
  display: block;
}
.section {
  padding: 2em 0;
  display: flex;
  min-height: 100vh;
  align-items: center;
}
.container {
  margin-inline: auto;
  max-width: 75rem;
  width: 85%;
}
.input__group {
  display: flex;
  margin-top: 2em;
}
.qrcode-input {
  width: 100%;
  padding: 1em 0.75em;
  border: 1px solid #444343;
  border-radius: 3px;
  margin-bottom: 2em;
  margin-top: 0.75em;
}

.qrcode__container {
	padding: 100px;
  button {
    border: unset;
    background: hsl(231, 77%, 90%);
    padding: 1em 0.75em;
    color: hsl(var(--font-color));
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.user-check-box {
  margin-left: 0px !important;
}
// .nav-pills .nav-link.active.complete-tab {
//   color:gold
// }
// .nav-pills .nav-link.active.incomplete-tab {
//   color:silver
// }
// Live Transfer
.call-log-main {
  background-color: #EDEDED;
  // cursor: pointer;
}
.live-enable {
  display:inline !important;
}
.log-selected {
  background-color: #c7c7e6 !important;
}

.notification-dropdown{
	transform: translateY(-50px) !important;
}
.call-log-item {
   cursor: pointer;
}
.call-log-body {
 height:500px;
 overflow-y: auto;
}
.log-text{
  margin-bottom:0px !important
}
 .pagination{
  margin:auto
}
.li.page-item.page-link{
	padding: 0 !important;
}
.communication-text {
  color:#141452
}
.red-text {
  color:#F14A4A !important
}
.call-card {
  background-color: #E8F2F0;
}
.tab-convert {
  min-width: 25%;
  border: 0px !important;
  margin: 0 !important;
  // border-bottom: 1px solid #82868b !important;
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tab-revert {
  box-shadow: -2px -3px 5px #35358a;
}
.custom-input {
  border-bottom: none;
  padding-bottom: 0.375rem;
  /* Add any additional styles to customize the input appearance */
}
.call-container{
  position:relative
}
.call-spinner{
  background-color: #28C76F;
  position:absolute;
  top:50%;
  left:50%;
}
.suppression-nav .nav-link {
  // border-radius: var(--bs-nav-pills-border-radius);
  border-radius: 0.5rem;
  border: 1px solid grey;
  margin-right: 5px;
}
// ............................................Agent Profile Page ................................//
.profile-header{
	
	img.rounded.img-fluid {
		width: 108px;
		height: 108px;
		border-radius: 6px;
		border: 4px solid rgb(255, 255, 255);
	}
	.profile-card-image {
		background-color: #f0808087; /* For browsers that do not support gradients */
  		background-image: linear-gradient(93.97deg, #141452 0.26%, rgba(218, 59, 59, 0) 98.44%);
	}
	img.card-img-top {
		opacity: 0;
	}
	.profile-details{
    padding: 0rem 1.5rem 0.1rem;
    margin-top: -6.5rem;
	display: flex;
    justify-content: space-between;
    align-items: flex-start;
	}
	.profile-content-section{
		display: flex;
		flex-direction: column;
		width: auto;
	}
	.profile-header-nav {
		padding: 0 2.5rem 0 12rem;
	}
	.content-pf{
		padding-left: 1rem;
	}
 


}

//.............................................Checkout...........................................//
.address-list{
  border-bottom:1px solid #ebe9f1
}

//...............................Faq.......................................................
.faq-header {
  font-size: 1.07rem;
  font-weight: 500;
}

.category-body {
  max-height:170px;
  overflow-y: auto;
}

.faq-nav .nav-pills .nav-link {
  border-radius: 1rem !important;
}

.file-input-height {
  height:auto !important;
  padding:0rem !important
}
.faq-file .uppy-Root {
  height:150px !important;
}
.nav-faq {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.notification-dropdown{
	 transform: translateY(-38px)  !important;
   z-index: 100;
}
.main-input-field {
  position: relative;
  transition: transform 0.3s;
}
.sub-input-wrap {
  position:absolute;
  top:10px;
  left:25px;
}

.location-text-field:focus + .sub-input-wrap {
  transform: translateY(-30px) translateX(-9px) !important;
}

.token-input-field {
  position: relative;
  transition: transform 0.3s;
}
.token-sub-input-wrap {
  position:absolute;
  top:10px;
  left:25px;
}
.filter-button:focus{
  outline: none !important;
  background-color: #fff !important;
}
.token-text-field:focus + .token-sub-input-wrap {
  transform: translateY(-30px) translateX(-9px) !important;
}
.agent-dropdown {
  width:85% !important
}
.custom-dropdown {
  width:115px !important
}
.download-button {
  background-color: #141452 !important;
}
//new login 
.login-form-container {
	width:50%;
}
.auth-login-form {
  .input-group {
    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 10rem;
      border-bottom-left-radius: 10rem;
    }
  }
  .input-group-text {
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
  }
  .custom-button-login {
    background-image: linear-gradient(90deg, #11bde1, #808cff)
  }
}
.custom-button{
  background-color: #725dd4;
  border-radius: 0.358rem;
  padding: 7px;
  a {
    color:#fff !important;
  }
}
.ai-button {
  border: 1px solid #e93d3d;
  padding: 7px;
  border-radius: 0.358rem;
}
// .gnahTY {
//   display:initial !important
// }
// .forRdO {
//   overflow-x: hidden !important;
// }
@media (max-width:1024px) {
	.login-form-container {
		width:100%;
	}
}
@media screen and (min-width: 768px) {
  .section {
    padding: 0;
  }
  .qrcode-input {
    margin: 0;
  }
  .qrcode__container {
    display: flex;
    align-items: center;
  }
  .input__group {
    margin-left: 3em;
  }
  .qrcode-input {
    margin-bottom: 2em;
    margin-top: 0.75em;
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .qrcode__container {
    // display: flex;
    // align-items: center;
    padding: 30px;
  }
  #qrCode {
    width: 100% !important;
    height: auto !important;
  }
}